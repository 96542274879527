<template>
   <div id="rg-portfolio">
      <big-title>
         Mi <span>Portafolio</span>.
      </big-title>

      <vx-card title="Tus inversiones de impacto en un solo lugar.">
         <div class="vx-row">
            <div class="vx-col w-full mb-4">
               <p>Consulta el desglose de tu portafolio en RedGirasol y descarga los datos que
                 requieras para mantener organizadas tus inversiones. Las cifras económicas son expresadas en moneda nacional.</p>
            </div>
         </div>

        <div class="vx-row mt-4">
          <div class="vx-col w-full mb-3">
            <ul class="centerx options-display">
              <li class="mr-6">
                <vs-radio color="success" v-model="periodType" vs-value="history">Histórico</vs-radio>
              </li>
              <li class="mr-6">
                <vs-radio v-model="periodType" vs-value="default">Año en curso</vs-radio>
              </li>
              <li class="mr-6">
                <vs-radio color="success" v-model="periodType" vs-value="custom">Periodo personalizado</vs-radio>
              </li>
            </ul>
          </div>
        </div>

        <div class="vx-row mt-5" v-if="onCustomPeriod">
          <div class="vx-col xxl:w-1/3 xl:w-40% lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
            <label class="vs-input--label bold">Periodo inicial</label>
            <div class="vx-row">
              <div class="vx-col w-40%">
                <v-select
                  name="yearSelect"
                  class="vs-custom"
                  v-model="customPeriodTop.year"
                  :options="customPeriodTopYears"
                  :clearable="false"
                  @input="changedSelectedPeriod()">
                </v-select>
              </div>
              <div class="vx-col w-60%">
                <v-select
                  name="yearSelect"
                  class="vs-custom"
                  v-model="customPeriodTop.month"
                  :options="customPeriodTopMonths"
                  :clearable="false"
                  :reduce="item => item.value"
                  @input="changedSelectedPeriod()">
                </v-select>
              </div>
            </div>
          </div>

          <div class="vx-col xxl:w-1/6 xl:w-20% lg:w-0 w-0 mb-5" v-if="!isLgOrSmallerScreen">
          </div>

          <div class="vx-col xxl:w-1/3 xl:w-40% lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5">
            <label class="vs-input--label bold">Periodo final</label>
            <div class="vx-row">
              <div class="vx-col w-40%">
                <v-select
                  name="yearSelect"
                  class="vs-custom"
                  v-model="customPeriodBottom.year"
                  :options="customPeriodBottomYears"
                  :clearable="false"
                  @input="changedSelectedPeriod()">
                </v-select>
              </div>
              <div class="vx-col w-60%">
                <v-select
                  name="yearSelect"
                  class="vs-custom"
                  v-model="customPeriodBottom.month"
                  :options="customPeriodBottomMonths"
                  :clearable="false"
                  :reduce="item => item.value"
                  @input="changedSelectedPeriod()">
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mt-4" v-if="validPeriod">
          <div class="vx-col w-full">
            <h6>Mostrando datos para el periodo: {{currentPeriodFormat}}</h6>
          </div>
        </div>

        <div class="vx-row mt-4" v-if="!validPeriod">
          <div class="vx-col w-full">
            <h6 class="text-danger">El periodo definido es inválido.</h6>
          </div>
        </div>

        <vs-button
          color="primary"
          icon-pack="feather"
          size="small"
          type="border"
          class="px-3 mt-4"
          icon="icon-download"
          @click="downloadCSV"
          v-if="hasResume && validPeriod">Descargar datos</vs-button>
      </vx-card>

     <div class="vx-row">
       <div class="vx-col xl:w-1/3 lg:w-1/3 md:w-full sm:w-full w-full mt-5">
         <vx-card :title="'Desglose ' + currentPeriodFormat">
           <vs-list v-if="resume">
             <vs-list-header title="Saldo del periodo"></vs-list-header>
             <vs-list-item title="Saldo inicial">{{ explicitMoneyFormat(initialBalance) }}</vs-list-item>
             <vs-list-item title="+ Abonos">{{ explicitMoneyFormat(resume.deposits) }}</vs-list-item>
             <vs-list-item title="- Retiros">{{ explicitMoneyFormat(resume.withdraws) }}</vs-list-item>
             <vs-list-item title="- Inversiones">{{ explicitMoneyFormat(resume.investments) }}</vs-list-item>
             <vs-list-item title="+ Intereses">{{ explicitMoneyFormat(resume.applicant_interests) }}</vs-list-item>
             <vs-list-item title="+ Intereses moratorios">{{ explicitMoneyFormat(resume.applicant_moratorium_interests) }}</vs-list-item>
             <vs-list-item v-if="resume.applicant_penalties != 0" title="+ Penalizaciones">{{ explicitMoneyFormat(resume.applicant_penalties) }}</vs-list-item>
             <vs-list-item title="+ Bonificaciones">{{ explicitMoneyFormat(resume.bonus) }}</vs-list-item>
             <vs-list-item title="+ IVA">{{ explicitMoneyFormat(resume.all_earnings_tax) }}</vs-list-item>
             <vs-list-item title="- Comisiones">{{ explicitMoneyFormat(resume.all_fees) }}</vs-list-item>
             <vs-list-item title="- IVA comisiones">{{ explicitMoneyFormat(resume.fee_tax) }}</vs-list-item>
             <vs-list-item title="- Retenciones IVA">{{ explicitMoneyFormat(resume.iva_withholdings) }}</vs-list-item>
             <vs-list-item title="- Retenciones ISR">{{ explicitMoneyFormat(resume.isr_withholdings) }}</vs-list-item>
             <vs-list-item title="+ Amortizaciones">{{ explicitMoneyFormat(resume.amortizations) }}</vs-list-item>
             <vs-list-item title="+ Recuperaciones">{{ explicitMoneyFormat(resume.recoveries) }}</vs-list-item>
             <vs-list-item title="+ Venta de participación">{{ explicitMoneyFormat(resume.shares_amount_sold) }}</vs-list-item>
             <vs-list-item title="- Compra de participación">{{ explicitMoneyFormat(resume.shares_amount_bought) }}</vs-list-item>
             <vs-list-item title="= Saldo final"><p class="bold text-lg">{{ explicitMoneyFormat(resume.final_balance) }}</p></vs-list-item>
             <!-- <vs-list-item title="Inicial">{{ explicitMoneyFormat(initialFixedInvestment) }}</vs-list-item>
             <vs-list-item title="+ Inversiones">{{ explicitMoneyFormat(resume.investments) }}</vs-list-item>
             <vs-list-item title="- Amortizaciones">{{ explicitMoneyFormat(resume.amortizations) }}</vs-list-item>
             <vs-list-item title="- Recuperaciones">{{ explicitMoneyFormat(resume.recoveries) }}</vs-list-item>
             <vs-list-item title="- Quebrantos">{{ explicitMoneyFormat(resume.loan_loss_provision) }}</vs-list-item>
             <vs-list-item v-if="resume.shares_amount_sold != 0" title="- Venta de participación">{{ explicitMoneyFormat(resume.shares_amount_sold) }}</vs-list-item>
             <vs-list-item v-if="resume.shares_amount_bought != 0" title="+ Compra de participación">{{ explicitMoneyFormat(resume.shares_amount_bought) }}</vs-list-item>
             <vs-list-item v-if="resume.shares_amount_transfer_sent != 0" title="- Participación transferida">{{ explicitMoneyFormat(resume.shares_amount_transfer_sent) }}</vs-list-item>
             <vs-list-item v-if="resume.shares_amount_transfer_received != 0" title="+ Participación recibida">{{ explicitMoneyFormat(resume.shares_amount_transfer_received) }}</vs-list-item>
             <vs-list-item title="= Final"><p class="bold text-lg">{{ explicitMoneyFormat(fixedInvestment) }}</p></vs-list-item> -->
           </vs-list>
           <div v-else><p>No hay datos para el último periodo.</p></div>
         </vx-card>
       </div>

       <div class="vx-col xl:w-2/3 lg:w-2/3 md:w-full sm:w-full w-full">
         <vx-card class="mt-5" v-if="!isSmallerScreen">
           <div class="vx-row mb-2">
             <div class="vx-col w-full">
               <p class="card-sub-title" v-if="validPeriod">{{currentPeriodFormat}}</p>
             </div>
           </div>
           <div class="vx-row mb-2">
             <div class="vx-col w-full">
               <vue-apex-charts type="line" height="360" :options="chartOptions" :series="series"></vue-apex-charts>
             </div>
           </div>
         </vx-card>
         <div class="vx-row mb-2">
          <div class="vx-col lg:w-1/2 md:w-full sm:w-full w-full">
            <vx-card class="mt-5">
              <vs-list>
                <vs-list-header title="Inversiones"></vs-list-header>
                <vs-list-item v-for="(item, index) in currentFixedInvestmentProjects" :title="assetCreditStatusName(item.asset_status, item.credit)+' '+countFormat(item.total)">{{ explicitMoneyFormat(item.investment) }}</vs-list-item>
              </vs-list>
            </vx-card>              
          </div>
          <div class="vx-col lg:w-1/2 md:w-full sm:w-full w-full">
            <vx-card class="mt-5">
              <vs-list>
                <vs-list-header title="Portafolio"></vs-list-header>
                <vs-list-item title="Saldo disponible">{{ explicitMoneyFormat(finalBalance) }}</vs-list-item>
                <vs-list-item title="Compromiso de inversión">{{ explicitMoneyFormat(investmentCommitment) }}</vs-list-item>
                <vs-list-item title="Inversión fija">{{ explicitMoneyFormat(fixedInvestment) }}</vs-list-item>
                <vs-list-item title="Total"><p class="bold text-lg">{{ explicitMoneyFormat(briefcase) }}</p></vs-list-item>
              </vs-list>
            </vx-card>
          </div>
        </div>
       </div>
     </div>

     <div class="vx-row mt-6">
       <div class="vx-col w-full">
         <vx-card>
           <div class="vx-row mb-1">
             <div class="vx-col w-full">
               <h1 class="extrabold text-4xl">Resumen de <span class="text-primary">Inversiones.</span></h1>
             </div>
           </div>
         </vx-card>
       </div>
     </div>

     <!-- <div class="vx-row mt-6">
       <div class="vx-col w-full">
         <vx-card title="¿Cómo se compone tu portafolio?">
           <div class="vx-row mb-1">
             <div class="vx-col w-full">
               <p>
                 Consulta el valor que representan tus proyectos hasta el <span class="bold">{{ projectsPortfolioDate }}</span> dentro de tu portafolio,
                 ya sea por su calificación o estado.
                 No olvides que le llamamos <span class="bold">semillas</span> a los proyectos que se encuentran en fondeo o proceso de instalación
                 y <span class="bold">girasoles</span> a los proyectos que ya están generando rendimientos.
               </p>
             </div>
           </div>
         </vx-card>
       </div>
     </div> -->

     <div class="vx-row">
       <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-full w-full mt-5">
         <vx-card title="Por calificación.">
          <vue-apex-charts type="donut" :options="qualificationsChartOptions" :series="qualificationsSeries" v-if="!ignoreQualificationsDonut"></vue-apex-charts>
          <vs-list>
            <vs-list-item v-for="(item, index) in qualificationsLegendData" :title="item.k">{{ item.v }}&emsp;&emsp;&emsp;&emsp;{{ item.p }}</vs-list-item>
          </vs-list>
           <div class="vx-row" v-if="ignoreQualificationsDonut">
             <div class="vx-col w-full">
               <h5 class="opacity-50">Aún no has invertido en ningún proyecto dentro de la Red.</h5>
             </div>
           </div>
         </vx-card>
       </div>
       <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-full w-full mt-5">
         <vx-card title="Inversiones.">
          <vue-apex-charts type="donut" :options="cstatusesChartOptions" :series="creditStatusesSeries" v-if="!ignoreSunflowersDonut" ></vue-apex-charts>
          <vs-list>
            <vs-list-item v-for="(item, index) in creditStatusesLegendData" :title="item.k">{{ item.v }}&emsp;&emsp;&emsp;&emsp;{{ item.p }}</vs-list-item>
          </vs-list>
           <div class="vx-row" v-if="ignoreSeedsDonut">
             <div class="vx-col w-full">
               <h5 class="opacity-50">Por el momento no tienes proyectos semilla activos en tu portafolio.</h5>
             </div>
           </div>
         </vx-card>
       </div>
       <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-full w-full mt-5">
        <vx-card title="Información adicional.">
          <vs-list>
            <vs-list-item v-for="(item, index) in extraData" :title="item.status_str+' '+countFormat(item.total)">{{ item.amount }}</vs-list-item>
          </vs-list>
        </vx-card>
      </div>
       <!-- <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-full w-full mt-5">
         <vx-card title="Tus girasoles.">
           <vue-apex-charts type="donut" :options="cstatusesChartOptions" :series="creditStatusesSeries" v-if="!ignoreSunflowersDonut" ></vue-apex-charts>
           <div class="vx-row mt-3 px-0">
             <div class="vx-col w-full" v-for="(cl, idx) in creditStatusesLegendData" :key="idx">
               <span class="bold float-left">
                 <feather-icon svgClasses="stroke-current text-dark h-3 w-3 b" class="pr-2" icon="CircleIcon" />{{cl.k}}
               </span>
               <span class="float-right">{{cl.v}} - {{cl.p}}</span>
             </div>
           </div>
           <div class="vx-row" v-if="ignoreSunflowersDonut">
             <div class="vx-col w-full">
               <h5 class="opacity-50">Por el momento no tienes girasoles activos en tu portafolio.</h5>
             </div>
           </div>
         </vx-card>
       </div> -->
     </div>

   </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";
import qualificationsHelper from "@mixins/qualificationsHelper";
import projectStatusesHelper from "@mixins/projectStatusesHelper";
import creditStatusesHelper from "@mixins/creditStatusesHelper";
import VueApexCharts from 'vue-apexcharts';
export default {
  mixins: [formatHelper, dateHelper, qualificationsHelper, projectStatusesHelper, creditStatusesHelper],
  components: {VueApexCharts},
  data(){
    return {
      currentFixedInvestmentProjects: [],
      extraData: [],
      isFirstTime: true,
      isMounted: false,
      periodType: 'history',
      defaultTopLimit:{
        year: 0,
        month: 0,
      },
      customTopLimit:{
        year: 0,
        month: 0,
      },
      bottomLimit:{
        year: 0,
        month: 0,
      },
      customPeriodTop: {
        year: 0,
        month: 0
      },
      customPeriodBottom: {
        year: 0,
        month: 0
      },
      resume: {
        deposits: 0,
        investments: 0,
        amortizations: 0,
        applicant_moratorium_interests: 0,
        applicant_interests: 0,
        acc_fixed_investment: 0,
        applicant_penalties: 0,
        bonus: 0,
        bonus_tax: 0,
        cashing_fee: 0,
        all_fees: 0,
        created_at: 0,
        earnings_tax: 0,
        all_earnings_tax: 0,
        fee_tax: 0,
        iva_withholdings: 0,
        isr_withholdings: 0,
        final_balance: 0,
        recoveries: 0,
        loan_loss_provision: 0,
        withdraws: 0,
        withdraws_fee: 0,
        balance_date: null,
      },
      hasResume: false,
      initialBalance: 0,
      briefcase: 0,
      availableCredit: 0,
      fixedInvestment: 0,
      investmentCommitment: 0,
      finalBalance: 0,
      initialFixedInvestment: 0,
      currentFilter: {
        filter: "default",
        startYear: null,
        endYear: null,
        startMonth: null,
        endMonth: null,
      },
      series: [{
        name: "Portafolio (MXN)",
        data: []
      }],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true
          },
          toolbar: {
            tools: {
              download: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'none'
          },
          locales:[{
            "name": "en",
            "options": {
              "toolbar": {
                "exportToPNG": "Descargar PNG",
                "exportToCSV": "Descargar CSV",
                "selectionZoom": "Zoom en área",
                "zoomIn": "Acercar",
                "zoomOut": "Alejar",
                "pan": "Paneo",
                "reset": "Reestablecer"
              }
            }
          }],
          defaultLocale: "en"
        },
        colors: ['#28DE18'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Portafolio (MXN)',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
        },
        yaxis: {
          labels:{
            formatter: (value) => { return this.explicitMoneyFormat(value, 0) },
          }
        },
        noData: {
          text: 'Cargando...'
        }
      },

      donutBaseChartOptions: { // example
        colors: ['#28DE18','#ffcf62','#BFD857','#34B2C1'],
        dataLabels: { enabled: false },
        donut: { labels: { show: false} },
        legend: {
          show: false,
          /*position: 'bottom',
          horizontalAlign: 'left',
          fontSize: '15px',
          formatter: function (seriesName, opts){
            return `${seriesName} - ${opts.w.globals.series[opts.seriesIndex]}`;
          }*/
        },
        tooltip: { y: { formatter: (value) =>{return this.explicitMoneyFormat(value, 0)} } },
        labels: ["Serie1", "Serie2", "Serie3", "Serie4", "Serie5"],
      },

      qualificationsChartOptions: {
        colors: ['#28DE18'],
        dataLabels: { enabled: false },
        donut: { labels: { show: false} },
        legend: { show: false},
        tooltip: { y: { formatter: (value) =>{return this.explicitMoneyFormat(value, 0)} } },
        labels: [],
      },
      pstatusesChartOptions: {
        colors: ['#28DE18'],
        dataLabels: { enabled: false },
        donut: { labels: { show: false} },
        legend: { show: false},
        tooltip: { y: { formatter: (value) =>{return this.explicitMoneyFormat(value, 0)} } },
        labels: [],
      },
      cstatusesChartOptions: {
        colors: ['#28DE18'],
        dataLabels: { enabled: false },
        donut: { labels: { show: false} },
        legend: { show: false},
        tooltip: { y: { formatter: (value) =>{return this.explicitMoneyFormat(value, 0)} } },
        labels: [],
      },
      qualificationsSeries: [],
      projectStatutesSeries: [],
      creditStatusesSeries: [],
      projectsPortfolioInfo: null,

      qualificationsLegendData: [],
      projectStatusesLegendData: [],
      creditStatusesLegendData: [],

      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      shortMonthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],

      ignoreQualificationsDonut: false,
      ignoreSeedsDonut: false,
      ignoreSunflowersDonut: false,
    }
  },
  async mounted(){
    this.isMounted = false
    this.showLoading(true)
    await this.getPortfolioLimits();
    await this.getPortfolio();
    await this.getProjectsPortfolio();
    this.isMounted = true
    this.showLoading(false)
  },
  watch: {
    periodType: async function (old, newval){
      if(old !== newval){
        await this.getPortfolio();
      }
    }
  },
  computed:{
    projectsPortfolioDate(){
      if(this.projectsPortfolioInfo && this.projectsPortfolioInfo.balanceDate){
        return this.regularDateFormat(this.projectsPortfolioInfo.balanceDate);
      }
      return "fin del mes pasado";
    },
    portfolioLastDateTitle(){
      if(this.resume && this.resume.balance_date){
        return `Desglose al ${this.regularDateFormat(this.resume.balance_date)}`;
      }
      else {
        return "Desglose";
      }
    },
    currentPeriodFormat(){
      if(!this.isMounted){
        return "";
      }
      if(this.onDefaultPeriod){
        if(this.bottomLimit.month === 1){
          return `${this.monthName(1)} ${this.bottomLimit.year}`
        }
        else {
          return `${this.monthName(1)} a ${this.monthName(this.bottomLimit.month)} ${this.bottomLimit.year}`
        }
      }
      else if(this.onCustomPeriod) {
        if(this.customPeriodTop.year === this.customPeriodBottom.year
          && this.customPeriodTop.month === this.customPeriodBottom.month){
          return `${this.monthName(this.customPeriodTop.month)} ${this.customPeriodTop.year}`;
        }
        else {
          return `${this.monthName(this.customPeriodTop.month)} ${this.customPeriodTop.year}
           a ${this.monthName(this.customPeriodBottom.month)} ${this.customPeriodBottom.year}`;
        }
      }
      else if(this.onHistoryPeriod){
        return "Histórico (al cierre del mes)";
        /*return `Histórico (${this.monthName(this.customTopLimit.month)} ${this.customTopLimit.year}
           a ${this.monthName(this.bottomLimit.month)} ${this.bottomLimit.year})`;*/
      }
    },
    validPeriod(){
      if(this.onDefaultPeriod || this.onHistoryPeriod){
        return true;
      }
      else {
        if(this.customPeriodBottom.year > this.customPeriodTop.year){
          return true;
        }
        else if(this.customPeriodBottom.year === this.customPeriodTop.year){
          return this.customPeriodBottom.month >= this.customPeriodTop.month
        }
        return false;
      }
    },
    onDefaultPeriod(){
      return this.periodType === 'default';
    },
    onHistoryPeriod(){
      return this.periodType === 'history';
    },
    onCustomPeriod(){
      return this.periodType === 'custom';
    },
    customPeriodTopYears(){
      let s = this.customTopLimit.year;
      let e = this.bottomLimit.year;
      let years = [];
      for (let i = s; i <= e; i++) {
        years.push(i);
      }
      return years;
    },
    customPeriodTopMonths(){
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({value: i, label: this.monthName(i) });
      }
      return months;
    },
    customPeriodBottomYears(){
      let s = this.customTopLimit.year;
      let e = this.bottomLimit.year;
      let years = [];
      for (let i = s; i <= e; i++) {
        years.push(i);
      }
      return years;
    },
    customPeriodBottomMonths(){
      let months = [];
      for (let i = 1; i <= 12; i++) {
        if(this.customPeriodBottom.year === this.bottomLimit.year && i > this.bottomLimit.month){
          continue;
        }
        months.push({value: i, label: this.monthName(i) });
      }
      return months;
    },
    isLgOrSmallerScreen(){
      return this.$store.state.windowWidth <= 1200;
    },
    isSmallerScreen(){
      return this.$store.state.windowWidth <= 576;
    },
  },
  methods: {
    countFormat(total){
      return total != null? '('+total+')':'';
    },
    monthName(index){
      return this.monthNames[index -1];
    },
    shortMonthName(index){
      return this.shortMonthNames[index -1];
    },
    async changedSelectedPeriod(){
      if(this.validPeriod){
        await this.getPortfolio();
      }
    },
    async getPortfolioLimits(){
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getPortfolioLimits`);
        this.defaultTopLimit = res.data.defaultTopLimit;
        this.customTopLimit = res.data.customTopLimit;
        this.bottomLimit = res.data.bottomLimit;
        this.customPeriodTop.year = this.defaultTopLimit.year;
        this.customPeriodTop.month = this.defaultTopLimit.month;

        this.customPeriodBottom.year = this.bottomLimit.year;
        this.customPeriodBottom.month = this.bottomLimit.month;
      }
      catch (e) {
      }
    },
    async getPortfolio(){
      this.showLoading(true)
      try {
        if(this.onDefaultPeriod){
          this.currentFilter.filter = 'default';
        }
        else if(this.onCustomPeriod){
          this.currentFilter.filter = 'period';
          this.currentFilter.startYear = this.customPeriodTop.year;
          this.currentFilter.startMonth = this.customPeriodTop.month;
          this.currentFilter.endYear = this.customPeriodBottom.year;
          this.currentFilter.endMonth = this.customPeriodBottom.month;
        }
        else if(this.onHistoryPeriod){
          this.currentFilter.filter = 'period';
          this.currentFilter.startYear = this.customTopLimit.year;
          this.currentFilter.startMonth = this.customTopLimit.month;
          this.currentFilter.endYear = this.bottomLimit.year;
          this.currentFilter.endMonth = this.bottomLimit.month;
        }
        else{
          return;
        }

        const res = await axios.post(`/api/v2/investor/${this.InvestorId}/getPortfolio`, this.currentFilter);
        const briefInfo = res.data
        const resume = briefInfo.portfolioResume;
        this.initialBalance = resume.initial_balance;
        this.availableCredit = resume.total_balance;
         this.fixedInvestment = briefInfo.currentPortfolio.fixed_investment;
        this.investmentCommitment = briefInfo.currentPortfolio.investment_commitment;
        this.finalBalance = briefInfo.currentPortfolio.final_balance;
        this.briefcase = briefInfo.currentPortfolio.total;
        this.initialFixedInvestment = resume.initial_fixed_investment;
        this.resume = resume;
        this.hasResume = (briefInfo.portfolioResume !== null);

        let seriesData = [];
        let seriesLabels = [];
        briefInfo.months.forEach(f => {
          seriesData.push(f.portfolio);
          seriesLabels.push(`${this.shortMonthName(f.month)} ${f.year}`);
        });

        this.series = [{
          name: "Portafolio (MXN)",
          data: seriesData
        }];

        this.chartOptions = {
          xaxis: {
            categories: seriesLabels
          }
        };
        // se genera log de accion
        if (this.isFirstTime) {
          this.saveInvestorLogAction("LC9-16");
          this.isFirstTime = false;
        }
      }
      catch (e) {
      }
      this.showLoading(false)
    },
    async getProjectsPortfolio() {
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getProjectsPortfolioNew`);
        const ppi = res.data;
        this.projectsPortfolioInfo = ppi;
        this.extraData = ppi.extraData;
        let qseries = [];
        let qlabels = [];
        let qcolors = [];
        let qlegends = [];
        ppi.qualificationsData.forEach(f => {
          qseries.push(f.investment);
          qlabels.push(f.qualificationGroup);
          qcolors.push(this.qualificationColor(f.qualificationGroup));
          qlegends.push({k: f.qualificationGroup, v: this.explicitMoneyFormat(f.investment), p: this.percentFormat(f.percent)});
        });
        this.qualificationsSeries = qseries;
        this.qualificationsChartOptions = { labels: qlabels, colors: qcolors };
        this.qualificationsLegendData = qlegends;
        if(!qseries.length){
          this.ignoreQualificationsDonut = true;
        }

        // let series = [];
        // let slabels = [];
        // let scolors = [];
        // let slegends = [];
        // ppi.statusesData.forEach(f => {
        //   series.push(f.investment);
        //   const sn = this.projectStatusName(f.status);
        //   slabels.push(sn);
        //   scolors.push(this.projectStatusColor(f.status));
        //   slegends.push({k: sn, v: this.explicitMoneyFormat(f.investment), p: this.percentFormat(f.percent)});
        // });
        // this.projectStatutesSeries = series;
        // this.pstatusesChartOptions = { labels: slabels, colors: scolors };
        // this.projectStatusesLegendData = slegends;
        // if(!series.length){
        //   this.ignoreSeedsDonut = true;
        // }

        let cseries = [];
        let clabels = [];
        let colors = [];
        let clegends = [];
        this.currentFixedInvestmentProjects = ppi.creditsData;
        this.currentFixedInvestmentProjects.forEach(f => {
          cseries.push(f.investment);
          const cn = this.assetCreditStatusName(f.asset_status, f.credit);
          const order = this.creditStatusOrder(f.asset_status, f.credit);
          clabels.push(cn);
          colors.push(this.creditStatusColor(f.asset_status, f.credit));
          clegends.push({k: cn, v: this.explicitMoneyFormat(f.investment), p: this.percentFormat(f.percent), order: order});
        });

        clegends.sort(function(a, b) {
          return a.order - b.order;
        });

        this.creditStatusesSeries = cseries;
        this.cstatusesChartOptions = { labels: clabels, colors: colors };
        this.creditStatusesLegendData = clegends;
        if(!cseries.length){
          this.ignoreSunflowersDonut = true;
        }
      }
      catch (e) {

      }
    },
    percentFormat(val){
      return `${val.toFixed(1)}%`;
    },
    async downloadCSV(){
      try {
        let filter;
        let startYear;
        let startMonth;
        let endYear;
        let endMonth;
        if(this.onDefaultPeriod){
          filter = 'default';
        }
        else if(this.onHistoryPeriod){
          filter = 'period';
          startYear = this.customTopLimit.year;
          startMonth = this.customTopLimit.month;
          endYear = this.bottomLimit.year;
          endMonth = this.bottomLimit.month;
        }
        else if(this.onCustomPeriod){
          filter = 'period';
          startYear = this.customPeriodTop.year;
          startMonth = this.customPeriodTop.month;
          endYear = this.customPeriodBottom.year;
          endMonth = this.customPeriodBottom.month;
        }
        let params = `filter=${filter}&startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/getPortfolioCSV?access_token=${this.AccessToken}&${params}`;
        window.open(url, "_blank");

        // se genera log de accion
        this.saveInvestorLogAction("LC9-17");
      }
      catch (e) {
        this.warn(e)
      }
    },
  }
}
</script>

<style lang="css" scoped>
.options-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.legend-list {
  display: inline-grid;
}
</style>
