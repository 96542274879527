export default {
   methods: {
      qualificationCode(projectQ) {
         switch (projectQ) {
            case 'A+':
               return 'a-plus';
            case 'A':
               return 'a';
            case 'A-':
               return 'a-minus';
            case 'B+':
               return 'b-plus';
            case 'B':
               return 'b';
            case 'B-':
               return 'b-minus';
            case 'C+':
               return 'c-plus';
            case 'C':
               return 'c';
            case 'C-':
               return 'c-minus';
            case 'D+':
               return 'd-plus';
            case 'D':
               return 'd';
            case 'D-':
               return 'd-minus';
            case 'E+':
               return 'e-plus';
            case 'E':
               return 'e';
            case 'E-':
               return 'e-minus';
            case 'F+':
               return 'f-plus';
            case 'F':
               return 'f';
            case 'F-':
               return 'f-minus';
            default:
               return  'cminus';
         }
      },
      qualificationColor(group) {
         switch (group) {
            case 'A':
               return '#67C820';
            case 'B':
               return '#3EAC5C';
            case 'C':
               return '#2D8F74';
            case 'D':
               return '#28707C';
            case 'E':
               return '#29537E';
            case 'F':
               return '#2F357A';
            default:
               return  '#67C820';
         }
      }
   }
}